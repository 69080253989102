var VitrineUtil = {
  getParamNameProducts: function () {
    return '%P_PRODUTOS';
  },

  getContainerProdutosHtml: function (id, name, linkSeeAll, unslickClass, vitrineId) {
    unslickClass = unslickClass ? unslickClass : '';

    let html = '        <div class="marketplace-products__conteudo">';
    html += '               <h2 class="marketplace-products__title">' + name + '</h2>';
    html += '               <div class="marketplace-products__ver-todas">';
    html +=
      '                   <a onclick="' +
      vitrineId +
      'DataLayerViewMoreProducts(this);" href="' +
      linkSeeAll +
      '" class="marketplace-products-ver-todas__link">';
    html += '                       <span class="marketplace-products-ver__title">Ver todas</span>';
    html += '                   </a>';
    html += '               </div>';
    html += '           </div>';

    html += '           <div class="marketplace-products__wrapper ' + unslickClass + '">';
    html += '               <div id="' + id + '" class="marketplace-products__list">';
    html += '                   ' + this.getParamNameProducts();
    html += '               </div>';
    html += '               <div class="marketplace-products__show-all">';
    html +=
      '                   <a onclick="' +
      vitrineId +
      'DataLayerViewMoreProducts(this);" href="' +
      linkSeeAll +
      '" class="button_hollow-curved ">Acessar todos os produtos</a>';
    html += '               </div>';
    html += '           </div>';
    return html;
  },

  createProductsHtml: function (vitrine, preUrl, imageUrl, slice, vitrineId) {
    let productsHtml = '';
    const products = VitrineUtil.getProducts(vitrine, slice);
    if (products && products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        const details = product.details;
        if (details.Loja !== undefined) {
          const imagem200 = product.images['200x200'];
          const urlProduct = this.concatURL(preUrl, product.url);
          const urlImage = this.concatURL(imageUrl, imagem200);
          const loja = product.details.Loja[0];
          productsHtml += VitrineUtil.getProdutoHtml(
            product.id,
            product.name,
            urlProduct,
            product.price,
            product.oldPrice,
            product.installment,
            urlImage,
            '',
            vitrineId,
            loja,
            i,
          );
        }
      }
    }
    return productsHtml;
  },

  createProductsHtmlParqueDPedro: function (vitrine, preUrl, imageUrl, slice, vitrineId) {
    let productsHtml = '';
    const products = VitrineUtil.getProducts(vitrine, slice);
    if (products && products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        //var details = product.details
        const imagem200 = product.images['200x200'];
        const urlProduct = this.concatURL(preUrl, product.url);
        const urlImage = this.concatURL(imageUrl, imagem200);
        //var loja = product.details.Loja[0];
        productsHtml += VitrineUtil.getProdutoHtml(
          product.id,
          product.name,
          urlProduct,
          product.price,
          product.oldPrice,
          product.installment,
          urlImage,
          '',
          vitrineId,
          '',
          i,
        );
      }
    }
    return productsHtml;
  },

  concatURL: function (preUrl, posUrl) {
    if (preUrl && posUrl) {
      return preUrl + posUrl;
    }
    return '';
  },

  formatToCurrencyBRL: function (value) {
    if (value) {
      return value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      });
    }
    return '';
  },

  getProdutoHtml: function (
    id,
    name,
    url,
    price,
    oldPrice,
    installment,
    image,
    highlight,
    vitrineId,
    loja,
    posicao,
  ) {
    // validate URL
    url = url ? url : '#';

    // check for Sale
    const pricesAreDifferent = oldPrice && oldPrice != price;
    const isSale = pricesAreDifferent && oldPrice > price;

    // check for Installment
    const hasInstallment =
      installment &&
      installment.count &&
      installment.price &&
      installment.count > 0 &&
      installment.price > 0;

    //  create the strings of values
    const priceStr = this.formatToCurrencyBRL(price);
    const oldPriceStr = this.formatToCurrencyBRL(oldPrice);
    const installmentPriceStr = this.formatToCurrencyBRL(installment.price);
    // aqui começa a montar o HTML
    let html =
      '<a posicao-data-layer="' +
      posicao +
      '" data-productid="' +
      id +
      '" onclick="' +
      vitrineId +
      'DataLayerClickProduct(this)" href="' +
      url +
      '" class="marketplace-products__item marketplace-products">';
    html += '       <div class="marketplace-products__image__container">';

    html += '           <figure class="marketplace-products__figure">';
    html += '               <img class="marketplace-products__image" data-lazy="' + image + '" />';
    html +=
      '               <img class="marketplace-products__image--mobile" data-lazy="' +
      image +
      '" />';
    html += '           </figure>';
    html += '           <div class="marketplace-products__info">';
    if (isSale) {
      const percetange = Math.round((1 - price / oldPrice) * 100);
      html +=
        '           <div class="marketplace-products__discount">' + percetange + '% OFF</div>';
    }

    html += '</div>';

    if (highlight != '') {
      html += '           <div class="marketplace-products__free-shipping">' + highlight + '</div>';
    }

    html += '       </div>';
    html += '       <div class="marketplace-products__content">';

    if (loja != '') {
      html += '           <span class="marketplace-products__store">' + loja + '</span>';
    }

    html += '               <h3 class="title">' + name + '</h3>';

    if (isSale) {
      html +=
        '           <p class="marketplace-products__fullprice"><del>de ' +
        oldPriceStr +
        ' por</del></p>';
      html += '           <span class="cta">' + priceStr + '</span>';
    } else {
      html += '           <span class="cta">' + priceStr + '</span>';
    }

    if (hasInstallment) {
      html +=
        '           <p class="marketplace-products__installments">' +
        installment.count +
        'x de ' +
        installmentPriceStr +
        ' sem juros</p>';
    }

    html += '   </div>';
    html += ' </a>';
    return html;
  },

  getVitrineByName: function (data, position, vitrineName) {
    // pega a posição
    if (data && data[position]) {
      const first = data[position];
      // verifica se a posição tem registros (vitrines para a posição)
      if (first && first.length > 0) {
        for (let i = 0; i < first.length; i++) {
          // pega o atual e valida se ele displays (produtos) e verifica se o nome é o procurado
          const current = first[i];
          if (current && current.name == vitrineName) {
            return current;
          }
        }
      }
    }
    return null;
  },

  // position --> top, center or bottom
  // tipoProdutos --> se deseja retornar os produtos de referência ou recomendados (null pega todos)
  // slice --> se deseja trazer a lista limitada (os 15 primeiros, os 20, etc.)
  getProducts: function (vitrine, slice) {
    if (VitrineUtil.isValidVitrine(vitrine)) {
      // tipos de produtos retornados na API
      const nameRec = 'recommendations';

      // cria a lista de retorno
      const productsDisplays = [];

      // realiza o for nos displays
      for (let k = 0; k < vitrine.displays.length; k++) {
        const currentDisplay = vitrine.displays[k];

        productsDisplays.push.apply(productsDisplays, currentDisplay[nameRec]);
      }

      // valida se passou slice
      if (slice && slice > 0 && productsDisplays.length > 0) {
        return productsDisplays.slice(0, slice);
      }
      return productsDisplays;
    }
    return new Array();
  },

  // position --> top, center or bottom
  // vitrineName --> é o ID da vitrine dentro da position desejada acima
  // tipoProdutos --> se deseja retornar os produtos de referência ou recomendados (null pega todos)
  // slice --> se deseja trazer a lista limitada (os 15 primeiros, os 20, etc.)
  getProductsFromResponse: function (data, position, vitrineName, tipoProdutos, slice) {
    const vitrine = this.getVitrineByName(data, position, vitrineName);
    return this.getProducts(vitrine, tipoProdutos, slice);
  },

  // verifica se é uma vitrine válida
  isValidVitrine: function (vitrine) {
    return (
      vitrine && vitrine.id && vitrine.title && vitrine.displays && vitrine.displays.length > 0
    );
  },
};
